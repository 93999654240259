/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function show_usernames_evenement(){

    var num_guests = $('#wv-aatalgasten option:selected').val();

    $('.form-group.usernames .name-holder').hide();

    $( ".form-group.usernames .name-holder" ).each(function( index ) {

      var item = index + 1;

      if(item <= num_guests){
        $('.form-group.usernames .name-holder.name-'+item).show();  
      }

    });

  }

  show_usernames_evenement();

  $('#wv-aatalgasten').change(function(){

    show_usernames_evenement();

  });

  $('#placeholder_companyname').text($('#get_companyname').val());

  $('.pb-blocks .photo-block .photo-holder').matchHeight({
      byRow: false,
      property: 'height'
  });

	$(".stick-in-parent").stick_in_parent({
    offset_top:0
  });

  $('.event-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow:'.event-slide-left',
    nextArrow:'.event-slide-right',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  // $('.service-block a').smoothScroll({offset: -140});

  $('.logo-holder-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 691,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.single-item').slick({
    // prevArrow:'.slide-left',
    // nextArrow:'.slide-right',
    appendDots:'.single-item-dots',
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  $('.multiple-items').slick({
    appendDots:'.multiple-items-dots',
    dots: true,
    infinite: true,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1
        }
      }
    ]
 });

  function set_body_class(height)
  {
    if(height  > 1) {
      $('body').addClass('scrolling');
    }else{
      $('body').removeClass('scrolling');
    }
  }
 
  $(window).scroll(function() {
    var height = $(window).scrollTop();
    set_body_class(height);
  });

  var height = $(window).scrollTop();
  set_body_class(height);

  var $menu = $("#primary-menu").mmenu({
    "extensions": [
        "position-right"
     ]
    // options
  }, {
    // configuration
    clone: true,
    scrollBugFix:{
      fix:true
    },
    offCanvas: {
      pageSelector: "#page-wrapper"
    }
  });

  var $icon = $("#open-menu");
  var API = $menu.data( "mmenu" );

  $icon.on( "click", function() {
     if($(this).hasClass('is-active')){
      API.close();
     }else{
      API.open();
     }
  });

  API.bind( "open:finish", function() {
     setTimeout(function() {
        $icon.addClass( "is-active" );
     }, 100);
  });
  API.bind( "close:finish", function() {
     setTimeout(function() {
        $icon.removeClass( "is-active" );
     }, 100);
  });














  

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
